import classNames__bind from 'classnames/bind.js';
import { isEqual } from 'lodash';
import React, { memo, type ReactNode } from 'react';
import ___Table_less_ from './Table.less'; const classNames = classNames__bind.bind(___Table_less_);

interface TableProps {
    className?: string;
    header?: ReactNode;
    children: ReactNode;
}

export default memo(function Table({ className, header, children }: TableProps) {
    return (
        <div className={classNames('Table', className)}>
            {header && <div className={classNames('Head')}>{header}</div>}
            <div className={classNames('Body')}>{children}</div>
        </div>
    );
}, isEqual);
