import classNames__bind from 'classnames/bind.js';
import { isEqual } from 'lodash';
import React, { type HTMLAttributes, memo, type ReactNode } from 'react';
import ___Cell_less_ from './Cell.less'; const classNames = classNames__bind.bind(___Cell_less_);

interface CellProps<T extends HTMLElement = HTMLDivElement> extends HTMLAttributes<T> {
    children?: ReactNode;
}

export default memo(function Cell({ role = 'cell', className, children, ...other }: CellProps) {
    return (
        <div role={role} className={classNames('Cell', className)} {...other}>
            {children}
        </div>
    );
}, isEqual);
