import { isEqual } from 'lodash';
import React, { memo } from 'react';
import ___Loader_less_ from './Loader.less'; import __classNames__bind from 'classnames/bind.js'; const __classNames = __classNames__bind.bind(___Loader_less_);

export default memo(function Loader() {
    return (
        <div className={__classNames('Loader')}>
            <div key="0" />
            <div key="1" />
            <div key="2" />
            <div key="3" />
        </div>
    );
}, isEqual);
