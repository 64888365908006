import {
    type EventHandler,
    type MouseEvent,
    type MouseEventHandler,
    type PointerEvent,
    type PointerEventHandler,
    type TouchEvent,
    type TouchEventHandler,
    useCallback,
    useMemo,
    useRef,
} from 'react';

export type PressEvent<T = Element> = PointerEvent<T> | TouchEvent<T> | MouseEvent<T>;
export type PressEventHandler<T = Element> = EventHandler<PressEvent<T>>;

type LongPressEvents<T = Element> = {
    onClick: (e: MouseEvent<T>) => void;
    onContextMenu: (e: MouseEvent<T>) => void;
} & (
    | {
          onPointerDown?: PointerEventHandler<T>;
          onPointerMove?: PointerEventHandler<T>;
          onPointerUp?: PointerEventHandler<T>;
          onPointerLeave?: PointerEventHandler<T>;
      }
    | {
          onTouchStart?: TouchEventHandler<T>;
          onTouchEnd?: TouchEventHandler<T>;
          onTouchMove?: TouchEventHandler<T>;
      }
    | {
          onMouseDown?: MouseEventHandler<T>;
          onMouseUp?: MouseEventHandler<T>;
          onMouseMove?: MouseEventHandler<T>;
          onMouseLeave?: MouseEventHandler<T>;
      }
);

export default function useLongPress<T = Element>(
    onLongPress: PressEventHandler<T>,
    onShortPress?: PressEventHandler<T>,
    duration = 400
): LongPressEvents<T> {
    const timerRef = useRef<NodeJS.Timeout>();
    const longPressRef = useRef(false);
    const shortPressRef = useRef(false);

    const onStart = useCallback(
        (e: PressEvent<T>) => {
            longPressRef.current = false;
            shortPressRef.current = false;
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                if (!shortPressRef.current) {
                    longPressRef.current = true;
                    onLongPress(e);
                }
            }, duration);
        },
        [duration, onLongPress]
    );

    const onEnd = useCallback(
        (e: PressEvent<T>) => {
            if (!longPressRef.current && onShortPress) {
                shortPressRef.current = true;
                clearTimeout(timerRef.current);
                setTimeout(() => {
                    onShortPress?.(e);
                }, 100);
            }
        },
        [onShortPress]
    );

    const onMove = useCallback(() => {
        clearTimeout(timerRef.current);
    }, []);

    const onClick = useCallback((e: MouseEvent<T>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onContextMenu = useCallback((e: MouseEvent<T>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return useMemo(() => {
        if (window.PointerEvent) {
            return {
                onPointerDown: onStart,
                onPointerMove: onMove,
                onPointerUp: onEnd,
                onPointerLeave: onMove,
                onClick,
                onContextMenu,
            };
        }
        if (window.TouchEvent) {
            return {
                onTouchStart: onStart,
                onTouchEnd: onEnd,
                onTouchMove: onMove,
                onClick,
                onContextMenu,
            };
        }
        return {
            onMouseDown: onStart,
            onMouseUp: onEnd,
            onMouseMove: onMove,
            onMouseLeave: onMove,
            onClick,
            onContextMenu,
        };
    }, [onStart, onEnd, onMove, onClick, onContextMenu]);
}
